import styled from 'styled-components';

export const StyledContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    
    h1 {
        font-size: 2.5em;
        margin-bottom: 20px;
        color: #333;
    }
    
    p {
        font-size: 1em;
        color: #666;
        margin-bottom: 20px;
    }
    
    ul {
        margin-left: 20px;
        margin-bottom: 20px;
    }
    
    li {
        margin-bottom: 10px;
    }
    
    a {
        color: #1a73e8;
        text-decoration: none;
    }
    
    a:hover {
        text-decoration: underline;
    }
`;
