import React, { useState } from "react";
import axios from "axios";
import { Form, Label, Input, Button ,FormContainer } from "./style";

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  if (localStorage.getItem("token")) {
    window.location.replace('/admin');
  }
  
  const handleLogin = () => {
    // Replace the API URL with your backend API URL for login
      const apiUrl = "https://news-app-back.vercel.app/api/login"; 
    //const apiUrl = "http://localhost:8000/api/login";

    // Make a POST request to the login API endpoint with the provided username and password
    axios
      .post(apiUrl, { username, password })
      .then((response) => {
        // Handle successful login here
        setError("");
        console.log("Login successful!");
        localStorage.setItem("token", response.data.token);
        window.location.replace('/admin');

      })
      .catch((error) => {
        // Handle login errors here
        setError("Invalid username or password");
      });
  };
  return (
    <div>
            <FormContainer>
      <h2>Login</h2>

        <Form>
          <Label>Username:</Label>
          <Input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Form>
        <Form>
          <Label>Password:</Label>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form>
        {error && <div style={{ color: "red" }}>{error}</div>}
        <Button onClick={handleLogin}>Login</Button>
      </FormContainer>
    </div>
  );
}

export default LoginPage;
