import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Form, Label, Input, TextArea, Button ,FormContainer ,StyledLink ,ImagePreview} from "./style";

const EditArticle = () => {
  const { articleId } = useParams();
console.log(articleId);
  const [articleData, setArticleData] = useState({
    image1: "",
    title: "",
    author: "",
    date: "",
    title_nd: "",
    description: "",
    body: "",
  });

  useEffect(() => {
    // Fetch the existing article data from the API using the articleId
    // Replace the API URL with your backend API URL for fetching a single article
    const apiUrl = `https://news-app-back.vercel.app/api/news/${articleId}`;

    axios
      .get(apiUrl)
      .then((response) => {
        // Set the article data in the state to populate the form fields
        setArticleData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching article data:", error);
        // You can handle errors here, e.g., show an error message to the user.
      });
  }, [articleId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArticleData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const apiUrl = `https://news-app-back.vercel.app/api/news/${articleId}`;
    //const apiUrl = `http://localhost:8000/api/news/${articleId}`;

    // Make a PUT request to the API to update the article
    axios
      .put(apiUrl, articleData)
      .then((response) => {
        console.log("Article updated successfully:", response.data);
        alert('updated succesfuly');
        window.location.replace('/admin');
      })
      .catch((error) => {
        console.error("Error updating article:", error);
        // You can handle errors here, e.g., show an error message to the user.
      });
  };

  return (
    <div>
      <FormContainer>
      <h2>Edit Article</h2>
      <Form onSubmit={handleSubmit}>
        <div>
          <Label>Image URL:</Label>
          <Input
            type="text"
            name="image1"
            value={articleData.image1}
            onChange={handleChange}
            required
          />
        {articleData.image1 && <ImagePreview src={articleData.image1} alt="Article Preview" />}

        </div>
        <div>
          <Label>Title:</Label>
          <Input
            type="text"
            name="title"
            value={articleData.title}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <Label>Author:</Label>
          <Input
            type="text"
            name="author"
            value={articleData.author}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <Label>Date:</Label>
          <Input
            type="text"
            name="date"
            value={articleData.date}
            onChange={handleChange}
            required
          />
        </div>
        {/* <div>
          <Label>Description:</Label>
          <Input
            type="text"
            name="description"
            value={articleData.description}
            onChange={handleChange}
          />
        </div> */}
        <div>
          <Label>Body:</Label>
          <TextArea
            name="body"
            value={articleData.body}
            onChange={handleChange}
            required
          />
        </div>
        <Button type="submit">Update Article</Button>
      </Form>
      </FormContainer>
      <StyledLink to={`/admin`}>Back</StyledLink>

    </div>
  );
};

export default EditArticle;
