import React from 'react';
import { StyledContainer } from "./privacypolicy.styles";

const PrivacyPolicy = () => {
    return (
        <StyledContainer>
            <h1>Privacy Policy</h1>
            <p>
                Welcome to GreenNews. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy explains how we collect, use, and protect your information when you visit our website.
            </p>
            <h2>1. Introduction</h2>
            <p>
                Welcome to GreenNews. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy explains how we collect, use, and protect your information when you visit our website.
            </p>
            <h2>2. Information We Collect</h2>
            <p>We do not collect personal information from visitors to our website. The information we may collect includes:</p>
            <ul>
                <li><strong>Log Data:</strong> Our servers automatically record information that your browser sends when you visit our site, such as IP address, browser type, and access times. This data helps us analyze and improve our website's performance.</li>
                <li><strong>Cookies:</strong> We use cookies to enhance your browsing experience. Cookies are small files stored on your device that help us understand how you use our site and customize your experience. You can disable cookies through your browser settings, but this may affect your ability to use certain features of the site.</li>
            </ul>
            <h2>3. Use of Information</h2>
            <ul>
                <li>Analyze website traffic and usage patterns.</li>
                <li>Improve website functionality and performance.</li>
                <li>Ensure website security and integrity.</li>
            </ul>
            <h2>4. Data Security</h2>
            <p>
                We implement industry-standard security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. While we strive to protect your information, no method of transmission over the internet or electronic storage is completely secure.
            </p>
            <h2>5. Third-Party Services</h2>
            <p>
                Our website may contain links to third-party websites and services. We do not control and are not responsible for the privacy practices of these third parties. We encourage you to review the privacy policies of any third-party sites you visit.
            </p>
            <h2>6. Children's Privacy</h2>
            <p>
                Our website is not intended for children under the age of 13. We do not knowingly collect or solicit personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information.
            </p>
            <h2>7. Changes to This Privacy Policy</h2>
            <p>
                We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically to stay informed about how we are protecting your information.
            </p>
            <span id="ezoic-privacy-policy-embed"></span>
            {/* <p>Your Privacy Policy can be found at <a href="https://g.ezoic.net/privacy/greennews.me">Ezoic Privacy Policy</a></p> */}
            <h2>8. Contact Us</h2>
            <p>
                If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
            </p>
            <p><strong>Email:</strong> admin@greennews.me</p>
            <p>Thank you for visiting GreenNews!</p>
            
        </StyledContainer>
    );
};

export default PrivacyPolicy;
