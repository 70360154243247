import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table , Button, StyledLink} from "./style";
import ReactPaginate from 'react-paginate';
import ProgressBar from 'react-bootstrap/ProgressBar';


function AdminDashboard() {
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [nextPageisLoading, setNextPageisLoading] = useState(false);

  useEffect(() => {
    
      fetchArticles();

  }, [currentPage]);

  const fetchArticles = async () => {
    try {
      const apiUrl = `https://news-app-back.vercel.app/api/news?page=${currentPage}&limit=5`;
      //const apiUrl = `http://localhost:8000/api/news?page=${currentPage}&limit=5`;
      const response = await axios.get(apiUrl);
      setArticles(response.data.articles);
      setTotalPages(response.data.totalPages); // Update hasMore based on totalPages
      setNextPageisLoading(false);

    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };


  const handleDelete = (articleId) => {
    const shouldDelete = window.confirm("Are you sure you want to delete this article?");
    if (shouldDelete) {
      const apiUrl = `https://news-app-back.vercel.app/api/news/${articleId}`;
      //const apiUrl = `http://localhost:8000/api/news/${articleId}`;

      // Make a DELETE request to the API to delete the article
      axios
        .delete(apiUrl)
        .then((response) => {
          console.log("Article deleted successfully:", response.data);
          // After successful deletion, update the state to remove the deleted article from the list
          setArticles((prevArticles) =>
            prevArticles.filter((article) => article._id !== articleId)
          );
        })
        .catch((error) => {
          console.error("Error deleting article:", error);
          // You can handle errors here, e.g., show an error message to the user.
        });
    }
  };

  return (
    <div>
      
            <StyledLink to="/admin/add-article">Add New Article</StyledLink>

      <h1>Admin Dashboard</h1>
      <Button onClick={() => {
        localStorage.removeItem("token");
        window.location.replace('/login');
      }}>Logout</Button>
      <Table>

        <thead>
          {nextPageisLoading && <ProgressBar animated now={100} />}
          <tr>
            <th>Title</th>
            <th>Author</th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {articles.map((article) => (
            <tr key={article._id}>
              <td>{article.title}</td>
              <td>{article.author}</td>
              <td>{article.date}</td>
              <td>
                {/* Use the Link component to navigate to the EditArticle page */}
                <StyledLink to={`/admin/edit-article/${article._id}`}>Edit</StyledLink>
                <Button onClick={() => handleDelete(article._id)}>Delete</Button>

              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={({ selected }) => {
              setCurrentPage(selected + 1);
              setNextPageisLoading(true);
            }}
            
            containerClassName="pagination"
            previousLabel="Previous"
            nextLabel="Next"
            disabledClassName="disabled"
            activeClassName="active"
          />
    </div>
  );
}

export default AdminDashboard;
