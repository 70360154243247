import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  background: ${(props) => props.theme.colors.background.primary};
  padding: 20px;
`;

export const NewsDetailContainer = styled.div`
  width: 800px;
  min-height: calc(100vh - 100px); // Height of the navBar
`;

export const NewsDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start (left) */
`;

export const Image = styled.div`
  width: 100%;
  max-width: 800px; /* Set a maximum width to prevent image from exceeding text width */
  margin-bottom: 30px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const Author = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.text.secondary};
  margin-bottom: 10px;
`;

export const Date = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.text.secondary};
  margin-bottom: 20px;
`;

export const Body = styled.p`
  font-size: 18px;
  line-height: 1.8;
  margin-top: 30px;
`;

