import React, { useState } from "react";
import axios from "axios";
import { Form, Label, Input, TextArea, Button ,FormContainer ,StyledLink ,ImagePreview} from "./style";

const AddArticle = () => {
  const [articleData, setArticleData] = useState({
    image1: "",
    title: "",
    author: "",
    date: "",
    title_nd: "",
    description: "",
    body: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArticleData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const apiUrl = "https://news-app-back.vercel.app/api/news";
/*     const apiUrl = "http://localhost:8000/api/news";
 */
    // Make a POST request to the API to add the article
    axios
      .post(apiUrl, articleData)
      .then((response) => {
        console.log("Article added successfully:", response.data);
      alert("article added");
      window.location.replace('/admin');
      })
      .catch((error) => {
        console.error("Error adding article:", error);
        // You can handle errors here, e.g., show an error message to the user.
      });
  };

  return (
    <div>
      <FormContainer>
      <h2>Add Article</h2>
      <Form onSubmit={handleSubmit}>
        <div>
          <Label>Image URL:</Label>
          <Input
            type="text"
            name="image1"
            value={articleData.image1}
            onChange={handleChange}
            required
          />
    {articleData.image1 && <ImagePreview src={articleData.image1} alt="Article Preview" />}
        </div>
        <div>
          <Label>Title:</Label>
          <Input
            type="text"
            name="title"
            value={articleData.title}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <Label>Author:</Label>
          <Input
            type="text"
            name="author"
            value={articleData.author}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <Label>Date:</Label>
          <Input
            type="text"
            name="date"
            value={articleData.date}
            onChange={handleChange}
            required
          />
        </div>
        {/* <div>
          <Label>Description:</Label>
          <Input
            type="text"
            name="description"
            value={articleData.description}
            onChange={handleChange}
          />
        </div> */}
        <div>
          <Label>Body:</Label>
          <TextArea
            name="body"
            value={articleData.body}
            onChange={handleChange}
            required
          />
        </div>
        <Button type="submit">Add Article</Button>
      </Form>
      </FormContainer>
      <StyledLink to={`/admin`}>Back</StyledLink>

    </div>
  );
};

export default AddArticle;
