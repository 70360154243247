import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
    StyledContainer, // Import the StyledContainer
    NewsDetailContainer, // Import the NewsDetailContainer
    Image,
    Title,
    Author,
    Date,
    Body,
    NewsDetailsWrapper, // Add the new import
  } from "./NewsDetailPage.styles"; // Import other styled components
  import { useDetectAdBlock } from "adblock-detect-react";
import Modal from "react-modal";

function NewsDetailPage() {
  const { id } = useParams(); // Get the article ID from the URL

  const [article, setArticle] = useState(null);

  const adBlockDetected = useDetectAdBlock();
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  useEffect(() => {
    Modal.setAppElement('body'); // Ideally, this should be at your top-level component
    if (adBlockDetected) {
      setIsModalVisible(true); // Show modal if adBlock is detected
    } else {
      setIsModalVisible(false); // Hide modal otherwise
          axios
      .get(`https://news-app-back.vercel.app/api/news/${id}`) // Replace with your backend API URL
      .then((response) => {
        setArticle(response.data);
      })
      .catch((error) => {
        console.error("Error fetching article:", error.message);
      });
    }
  }, [id, adBlockDetected]); // Fetch the article whenever the ID parameter in the URL changes

  if (!article) {
    // If the article data is not yet loaded, display a loading message or spinner
    return <div>Loading...</div>;
  }
return (
    <StyledContainer>
     <Modal
  isOpen={isModalVisible}
  contentLabel="Ad Blocker Detected"
  style={{
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }}
>
  <h2>Ad Blocker Detected</h2>
  <p>Please disable your ad blocker to view the content.</p>
  {/* Removed the close button to make the modal unclosable */}
</Modal>
{!isModalVisible && (
      <NewsDetailContainer>
        <NewsDetailsWrapper> {/* Use the NewsDetailsWrapper here */}
          <Title>{article.title}</Title>
          <Author>Author: {article.author}</Author>
          <Date>Date: {article.date}</Date>
          <Image>
          {/* https://soceephojox.com/4/7796635 */}
            <a href="https://wifeftopt.net/4/7810373" target="_blank" rel="noopener noreferrer">
              <img src={article.image1} alt={article.title} />
            </a>
          </Image>
          <Body>{article.body}</Body>
        </NewsDetailsWrapper>
      </NewsDetailContainer>
)}

    </StyledContainer>
  );
    
}

export default NewsDetailPage;
