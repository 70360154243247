import styled from "styled-components";
export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  background: ${(props) => props.theme.colors.background.primary};
  .content {
    width: 1140px;
    min-height: calc(100vh - 100px); // Height of the navBar

    .news-container {
      padding-top: 50px;
      display: flex;
    }

    .articles-section {
      display: flex;
      justify-content: center; /* Center the articles horizontally */
      max-width: 78%;
      flex-wrap: wrap;
      margin-left: auto; /* Add this line to center the articles */
      margin-right: auto; /* Add this line to center the articles */
    }

    .quick-infos-section {
      max-width: 22%;
      margin-left: 15px;
    }
  }
  a {
    text-decoration: none;
  }
    .scrollToTopBtn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  border: none;
  outline: none;
  background-color: #333;
  color: white;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  font-size: 24px;
}
`;
