import React, { useState , useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { NavBar } from "./components/Navbar";
import { WebsiteLogo } from "./components/WebsiteLogo";
import HomePage from "./pages/HomePage/HomePage";
import NewsDetailPage from "./pages/NewsDetailPage/NewsDetailPage";
import { COLORS, FONTS } from "./theme";

import AdminDashboard from "./pages/adminPage/adminPage";
import LoginPage from "./pages/adminPage/LoginPage";
import AddArticle from "./pages/adminPage/AddArticle";
import EditArticle from "./pages/adminPage/EditArticle";
import ProtectedRoute from "./pages/protectedRoute/protectedRoute";

import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react"

import PrivacyPolicy from "./pages/privacy/PrivacyPolicy";

function App() {
  const [isAuthenticated] = useState(!!localStorage.getItem("token"));

  useEffect(() => {
    // You can also perform additional checks here, e.g., verify the token with the server
    // and set isAuthenticated accordingly
  }, []);

  return (
    <ThemeProvider theme={{ colors: COLORS, fonts: FONTS }}>
      <Router>
        <WebsiteLogo />
        <NavBar />
        <Routes>
  <Route path="/" element={<HomePage />} />
  <Route path="/news/:id" element={<NewsDetailPage />} />
  <Route path="/login" element={<LoginPage />} />
  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
  {/* Protected Routes */}
  <Route path="/admin" element={<ProtectedRoute element={<AdminDashboard />} isAuthenticated={isAuthenticated} />} />
  <Route path="/admin/add-article" element={<ProtectedRoute element={<AddArticle />} isAuthenticated={isAuthenticated} />} />
  <Route path="/admin/edit-article/:articleId" element={<ProtectedRoute element={<EditArticle />} isAuthenticated={isAuthenticated} />} />
  {/* Add any other non-protected routes here */}
</Routes>

      </Router>
      <Analytics />
      <SpeedInsights />
    </ThemeProvider>
  );
}

export default App;
