import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spotlight } from "../../components/Spotlight";
import { StyledContainer } from "./homepage.styles";
import { ArticleCard } from "../../components/ArticleCard";
import { Link } from "react-router-dom";
import { useDetectAdBlock } from "adblock-detect-react";
import Modal from "react-modal";
import InfiniteScroll from "react-infinite-scroll-component";

function HomePage(props) {
  const [articles, setArticles] = useState([]);
  const adBlockDetected = useDetectAdBlock();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [page, setPage] = useState(1);
const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    Modal.setAppElement('body'); // Ideally, this should be at your top-level component
    if (adBlockDetected) {
      setIsModalVisible(true); // Show modal if adBlock is detected
    } else {
      setIsModalVisible(false); // Hide modal otherwise
      fetchArticles();
    }

  }, [adBlockDetected]);

const fetchArticles = async () => {
  if (!hasMore) return; // Stop fetching if there are no more articles
  try {
    const apiUrl = `https://news-app-back.vercel.app/api/news?page=${page}&limit=4`;
   // const apiUrl = `http://localhost:8000/api/news?page=${page}&limit=4`;
    const response = await axios.get(apiUrl);
    setArticles([...articles, ...response.data.articles]);
    setPage(page + 1); // Increment the page for the next fetch
    setHasMore(page < response.data.totalPages); // Update hasMore based on totalPages
  } catch (error) {
    console.error("Error fetching articles:", error);
  }
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth', // For a smooth scrolling effect
  });
};

  return (
    <StyledContainer>

    <Modal
      isOpen={isModalVisible}
      contentLabel="Ad Blocker Detected"
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      }}
    >
  <h2>Ad Blocker Detected</h2>
  <p>Please disable your ad blocker to view the content.</p>
</Modal>

{!isModalVisible && (
  <div className="content">
    <Spotlight />

      <InfiniteScroll
        dataLength={articles.length}
        next={fetchArticles}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
      >
      <div className="news-container">
        <div className="articles-section">
        {articles.map(({ _id, title, image1 }) => (
            <Link to={`/news/${_id}`} key={_id}>
              <ArticleCard
                title={title}
                img={image1}
              />
            </Link>
          ))}
        </div>
      </div>
      </InfiniteScroll>
      <button onClick={scrollToTop} className="scrollToTopBtn">↑</button>
  </div>
)}
    </StyledContainer>
  );
}

export default HomePage;
